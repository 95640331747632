.socials-container {
    align-items: center;
    display: flex;
    height: fit-content;
    justify-content: center;
}

.social-logos {
    width: 20px;
    margin-right: 14px;
}