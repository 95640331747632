.mainContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.inputBox {
    box-sizing: border-box;
    width: 100%;
    height: 44px;
    border-radius: 8px;
    padding: 10px 14px 10px 14px;
    border: 1.4px solid #CADEFF33;
    background-color: transparent;
    text-decoration: white;
    color: white;
    margin-top: 11px;
}

.inputBox:focus {
    outline: none;
    border: 1.4px solid #A2E4F4;

}

