
.pageContainer {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--secondary-light);

}

.mainContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 300px;
    background-color: var(--secondary-light);
}

.loadImage {
    height: auto;
    width: 100px;
}