.container {
    padding: 12.5px 18px 12.5px 18px;
    background-color: #1B2028;
    border-radius: 36px;
    cursor: pointer;
    margin: 0 16px 0 0;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;

}

.wideContainer {
    padding: 12.5px 18px 12.5px 18px;
    background-color: #1B2028;
    border-radius: 36px;
    cursor: pointer;
    margin: 0 16px 0 0;
    transition: 0.3s;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.noMargin {
    padding: 12.5px 18px 12.5px 18px;
    background-color: #1B2028;
    border-radius: 36px;
    cursor: pointer;
    margin: 0 0 0 0;
    transition: 0.3s; 
}

.container:hover {
    background-color: #1F252E;
}

.container:active {
    background-color: #232934;
    opacity: 70;
}

.clicked {
    background-color: #232934;
}

.containerDisabled {
    cursor: not-allowed;
}