/* Text */
.x-small-text {
    font-size: 10px;
    color: white;
    font-weight: 400;
    font-family: Inter-Light;
}

.small-text {
    font-size: 14px;
    color: white;
    font-weight: 400;
    font-family: Inter-Light;
    white-space: pre-wrap; /* or pre-line */

}

.medium-text {
    font-size: 16px;
    color: white;
    font-weight: 400;
    font-family: Inter;
}


.medium-text-alt {
    font-size: 22px;
    color: white;
    font-weight: 400;
}

.large-text {
    font-size: 25px;
    color: white;
    font-weight: 400;
    font-family: Inter-Light;
}

.x-large-text {
    font-size: 32px;
    color: white;
    font-family: Inter-Light;
}


/* Fonts */
.parabole {
    font-family: Parabole;
}


/* Decoration */
.underline {
    text-decoration: underline;
}

.no-underline {
    text-decoration: none;
}

.bold {
    font-weight: bold;
}

.medium-bold {
    font-weight: 500;
}

.centre-align {
    text-align: center;
}

.left-align {
    text-align: left;
}

.right-align {
    text-align: right;
}

.center {
    text-align: center;
}

.medium-text-width {
    width: 600px;
    max-width: 90%;
    text-align: center;
}

.no-wrap {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.grey-opacity {
    color: #CADEFF66;
}

.medium-weight {
    font-weight: 500;
}
