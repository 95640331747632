/* Structure */

.no-margin {
    margin: 0;
}

.no-padding {
    padding: 0px
}

.br-x-x-small {
    margin-bottom: 6px;
}


.x-small-padding {
    padding: 5px;
}

.small-padding {
    padding: 10px;
}

.medium-padding {
    padding: 20px;
}

.large-padding {
    padding: 40px;
}


/* Padding top */
.small-padding-top {
    padding-top: 10px;
}

.medium-padding-top {
    padding-top: 20px;
}

.large-padding-top {
    padding-top: 40px;
}


/* Padding bottom */
.small-padding-bottom {
    padding-bottom: 10px;
}

.medium-padding-bottom {
    padding-bottom: 20px;
}

.large-padding-bottom {
    padding-bottom: 40px;
}

/* Margin Top */
.small-margin-top {
    margin-top: 10px;
}

.medium-margin-top {
    margin-top: 20px;
}

.large-margin-top {
    margin-top: 40px;
}

/* Margin Bottom */
.small-margin-bottom {
    margin-bottom: 10px;
}

.medium-margin-bottom {
    margin-bottom: 20px;
}

.large-margin-bottom {
    margin-bottom: 40px;
}

.pointer {
    cursor: pointer;
}