.tonalGraph {
    border-radius: 10px;
    background-color: #232a3380;
}

.tonalPopupContent {
    padding: 20px;
    width: 200px;
    z-index: 4;
    border-radius: 10px;
    background-color: #232a33;
    opacity: 1 !important;
}