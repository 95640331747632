.pageContainer {
    height: 100vh;
    width: 100vw;
    background-color: var(--secondary-light);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.mainContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 300px;
}

.coinImage {
    height: 100px;
}

