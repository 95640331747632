.compPageContainer {
    align-items: center;
    background-color: #12161C;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;
    padding: 50px 50px 0px 50px;
}

.headerContainer {
    width: 100%;
    display: flex;
    height: auto;
    margin-bottom: 40px;
    justify-content: center;
    align-items: center;
}

.backButtonContainer {
    background-color: transparent;
    border: 0;
    flex: 1;
    display: flex;
    justify-content: flex-start;
}

.backArrow {
    height: 18px;
}

.backArrow:hover {
    cursor: pointer;
}

.nameContainer {
    flex: 1;
    display: flex;
    justify-content: center;
}

.songNameContainer {
    padding: 10px 18px 10px 18px;
    margin-top: 30px;
    background-color: #232a3380;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 10px;
}

.rightFiller {
    display: flex;
    justify-content: flex-end;
    flex: 1;
}

.button-text:hover {
    color: #a8e4f4;
}

.dataContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 30px;
    justify-content: flex-start;
}

.poweredBy {
    cursor: pointer;
    transition: 0.3s;
    z-index: 20;
    background-color: var(--secondary-light);
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    color: inherit; /* Use the same color as the rest of the text */
    text-decoration: none; /* Remove the underline */

}

.textPanel {
    margin-left: 20px;
    border-radius: 20px;
    display: block;
    height: fit-content;
    flex: 1;
    box-sizing: border-box;
}

.middleContainer{
    width: 70%;
    max-width: 900px;
    display: flex;
    margin-bottom: 60px;
    margin-top: 30px;
}

.margin-right {
    margin-right: 20px;
}

.margin-bottom {
    margin-bottom: 10px
}

.player-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.tonal-profile-container {
    width: 50%;
    height: fit-content;
}

.feedbackButton {
    position: fixed;
    background-color: var(--secondary-light);
    border: 0;
    padding: 15px;
    height: fit-content;
    border-radius: 10px;
    bottom: 50px;
    right: 50px;
    z-index: 3;
    transform: translateY(4px);
    transition: transform 0.1s ease-in-out;
}

.feedbackButton:hover {
    transition: 0.3s;
    background-color: #232934;
    cursor: pointer;
}


.feedbackContainer {
    position: fixed;
    background-color: var(--secondary-light);
    border: 0;
    padding: 15px;
    width: 400px;
    border-radius: 20px;
    bottom: 50px;
    right: 50px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    opacity: 1;
    transform: translateY(-4px);
    transition: transform 0.1s ease-in-out;
}

.feedbackCommentsContainer {
    width: 100%;
    min-height: 150px;
    border-radius: 20px;
    background-color: rgb(41, 49, 59, 0.8);
    border: none; 
    text-decoration: white;
    vertical-align: top;
    padding: 20px;
    box-sizing: border-box;
    font-size: 16px;
    color: white;
    font-weight: 400;
    font-family: Inter;
}

.feedbackEmailContainer {
    width: 100%;
    height: 60px;
    border-radius: 20px;
    background-color: rgb(41, 49, 59, 0.8);
    border: none; 
    text-decoration: white;
    padding: 20px;
    box-sizing: border-box;
    font-size: 16px;
    color: white;
    font-weight: 400;
    vertical-align: middle;
    font-family: Inter;
    margin-bottom: 15px;
}

.feedbackEmailContainer:focus {
    outline: none;

}

.vertical-top {
    vertical-align: top;
}

.feedbackCommentsContainer:focus {
    outline: none;
}

.submitContainer {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.submitButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12.5px 15px;
    gap: 8px;

    height: 44px;

    background: #A2E4F4;
    border-radius: 20px;
    border: 0
}

.submitButtonDisabled {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12.5px 15px;
    gap: 8px;

    height: 44px;

    background: grey;
    border-radius: 20px;
    border: 0
}

.submit-button:hover {
    cursor: pointer;
}

.closeButton {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-30%, -30%);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: red;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.tonalContainer {
    width: 100%;
    padding-right: 10px;
}

.metaHeader {
    min-width: 200px;
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 50px;
    padding: 16px;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
}

.innerMetaCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: '100%';
}

.left {
    padding: 20px;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    background-color: var(--secondary-light);
    display: flex;
    flex-basis: 1;
}

.right {
    display: flex;
    flex: 1;
    background-color: #232934;
    max-width: 200px;
    padding: 20px;
    border-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
  
/* Mobile Styles */
@media only screen and (max-width: 800px) {
    .middleContainer {
      flex-direction: column;
      width: 100%
    }
    .dataContainer {
        justify-content: center;
    }
    .rightDataPanel {
        margin-top: 30px;
    }
    .feedbackButton {
        display: none;
    }
    .textPanel {
        margin-left: 0px;
    }
    .rightFiller {
        /* display: none; */
    }
    .compPageContainer {
        padding: 50px 40px 0px 40px;
    }
    .metaHeader {
        flex-direction: column;
        flex: 1;
        min-width: none;

    }

    .innerMetaCont {
        width: 100%;
        min-width: none;
    }

  }