/* styles.module.css */
.success-notification {
    position: fixed;
    bottom: 35px;
    left: -300px; /* Initial position outside the viewport */
    transition: left 0.3s ease-out;
    background-color: rgba(167, 12, 12, 0.95);
    color: white;
    padding: 12.5px 18px;
    border-radius: 20px;
    z-index: 9999;
    display: flex;
    align-items: center;
  }
  
  .show {
    left: 35px; /* Final position when shown */
  }
  
  .success-message {
    margin-right: 20px;
    font-family: Inter;
    font-size: 14px;
    max-width: 350px;

  }
  
  .clear-button {
    background-color: transparent;
    border: none;
    color: white;
    font-weight: bold;
    cursor: pointer;
  }
  
  .cancelJob {
    height: 12px;
    cursor: pointer;
    margin: 0 5px 0 5px    
}