.singlePage {
    width: 100vw;
    box-sizing: border-box;
}

.mainPageContainer{
    height: 100vh;
    align-items: center;
    background-color: #12161C;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.lowerContainer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    max-width: 70%;
    width: 100%;
}

.poweredBy {
    cursor: pointer;
    transition: 0.3s;
    z-index: 20;
    background-color: var(--secondary-light);
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    color: inherit; /* Use the same color as the rest of the text */
    text-decoration: none; /* Remove the underline */

}