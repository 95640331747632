.container {
    padding: 12.5px 18px 12.5px 18px;
    background-color: #A2E4F4;
    border-radius: 36px;
    cursor: pointer;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.container:hover {
    background-color: #85d4e8;
}

.clicked {
    background-color: #1B2028;
}