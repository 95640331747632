.genre-page-container {
    min-height: 100vh;
    width: 100vw;
    background-color: #12161C;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px 40px 40px 40px;
    box-sizing: border-box;
}

.genreContainer {
    background-color: #1B2028;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    padding: 40px;
    max-width: 800px;
    box-sizing: border-box;

}

.genreButtonsContainer{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.genreButton {
  background-color: #232a33;
  padding: 15px;
  border-radius: 20px;
  min-width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: 6px;
}

.genreButton:hover {
  background-color: #21262e;
  cursor: pointer;
}

.singlePage {
  width: 100vw;
  box-sizing: border-box;
}

.mainPageContainer{
  min-height: 100vh;
  align-items: center;
  background-color: var(--secondary-light);
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.load-image {
  height: auto;
  width: 120px;
}

.stepContainer {
  background-color: var(--secondary);
  padding: 16px;
  border-radius: 16px;
}

.headerContainer {
  width: 100%;
  display: flex;
  height: auto;
  margin-bottom: 40px;
  justify-content: center;
  align-items: center;
}

.lowerContainer {
  align-items: center;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  max-width: 70%;
  width: 100%;
}

.backButtonContainer{
  background-color: transparent;
  border: 0;
  flex: 1;
  display: flex;
  justify-content: flex-start;
}