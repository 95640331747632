

.input-form {
    display: none;
}

.text-logo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.upload-button {
    background-color: transparent;
    border: 10px solid #12161C;
    height: 70px;
    width: 70px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    height: auto;
    width: 40px;
}