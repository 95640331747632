.pageContainer {
    background-color: var(--secondary);
    box-sizing: border-box;
    min-height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.mainContentContainer {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.signUpContainer {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 360px;
    margin-left: 20px;
    margin-right: 20px;
}
