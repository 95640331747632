/* New Theme */
:root {
    --primary: #A2E4F4;
    --primary-dark: #3F8291;
    --secondary: #12161C;
    --secondary-light: #1B2028;
    --accent: #CADEFF;
    --alt: #2D3644

}

/* Colours */
.off-grey {
    color: #878787
}

.roex-blue {
    color: #a8e4f4;
}

.roex-black {
    background-color: #12161C;
}

.roex-black-light {
    background-color: #232a33;
}

.black {
    color: black
}


.small-opacity {
    opacity: 0.9;
}

.medium-opacity {
    opacity: 0.7;
}

.heavy-opacity {
    opacity: 0.5;
}

