.drag-and-drop-container {
    background-color: #1B2028;
    width: 600px;
    max-width: 90%;
    height: 300px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;

}

.drag-and-drop-container:hover {
    cursor: pointer;
}

.dragging {
    background-color: #21262f
}

.input-form {
    display: none;
}

.text-logo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.upload-button {
    background-color: transparent;
    border: 10px solid #12161C;
    height: 70px;
    width: 70px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    height: auto;
    width: 40px;
}