.singlePage {
    width: 100vw;
    box-sizing: border-box;
}

.mainPageContainer{
    height: 100vh;
    align-items: center;
    background-color: var(--secondary-light);
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.load-image {
    height: auto;
    width: 120px;
}

.stepContainer {
    background-color: var(--secondary);
    padding: 16px;
    border-radius: 16px;
}

.headerContainer {
    width: 100%;
    display: flex;
    height: auto;
    margin-bottom: 40px;
    justify-content: center;
    align-items: center;
}

.lowerContainer {
    align-items: center;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    max-width: 70%;
    width: 100%;
}

.backButtonContainer{
    background-color: transparent;
    border: 0;
    flex: 1;
    display: flex;
    justify-content: flex-start;
}