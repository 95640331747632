.container {
    padding: 12.5px 18px 12.5px 18px;
    background-color: #FFFFFF;
    border-radius: 36px;
    cursor: pointer;
    margin: 0 0 0 0;
    transition: 0.3s;
    height: 44px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.disabledContainer {
    padding: 12.5px 18px 12.5px 18px;
    background-color: #FFFFFF80;
    border-radius: 36px;
    cursor: pointer;
    margin: 0 0 0 0;
    transition: 0.3s;
    height: 44px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: not-allowed;
}

.container:hover {
    background-color: #FFFFFFE5;
    opacity: 90;
}

.container:active {
    background-color: #FFFFFFE5;
    opacity: 80;
}

.clicked {
    background-color: #FFFFFFE5;
    opacity: 50;
}