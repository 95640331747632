.itemContainer {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 10px;
    margin-right: 10px;
    flex-grow: 1;
    background-color: #232a3380;
}

.warnImage {
    height: 14px
}

.warning {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-30%, -30%);
    width: 14px;
    height: 14px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.itemContainer:hover {
    cursor: pointer;
}

.dataItemContainer {
    display: flex;
    flex: 1;
}

.name-container {
    display: flex;
    flex: 1;
    align-items: center;
}

.popupContent {
    padding: 20px;
    width: 200px;
    z-index: 4;
    border-radius: 10px;
    background-color: #232a33;
}